import axios from '@axios'

import router from '@/router'   

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    fetchSurveyAttendees(ctx, { id }) {
      //console.log("single course  Fetching");
      return new Promise((resolve, reject) => {
        axios
          .get(`/survey/admin/getSurveyItemAttendees/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSurveyResults(ctx, { id }) {
      //console.log("single course  Fetching");
      return new Promise((resolve, reject) => {
        axios
          .get(`/survey/admin/getSurveyResults/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },


    

    fetchCourses(ctx, queryParams) {
      //console.log("courses Fetching");
      return new Promise((resolve, reject) => {
        axios
          .get('/survey/admin/surveys', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    
    addAttendeeToSubject(ctx, data) {
     

      var subitemid= router.currentRoute.query.s;
      //console.log("Updating survey Item with Id: "+subitemid);
      //console.log("with data");
      //console.log(data);

 

 
      return new Promise((resolve, reject) => {
        axios
          .post(`/survey/admin/addAttendeeToSurvey/${subitemid}`,data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateCourseInfo(ctx, { id }) {
      //console.log("update course  is launching with id : "+id);
      return ;
      return new Promise((resolve, reject) => {
        axios
          .get(`/lms/admin/course/getcoursedetail/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteAttende(ctx, { id }) {
       
//console.log("delete"+id);
 

var data={
  info:"surveyAttendeeDelete"
};

      return new Promise((resolve, reject) => {
        axios
          .put(`/survey/admin//updatesurveyattendee/${id}`,data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    
   
    addSurvey(ctx, userData) {

      //console.log("course Create triggered");
      //console.log(userData);

   
      return new Promise((resolve, reject) => {
        axios
          .post('/survey/admin/survey', userData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    draftCourse(ctx, userData) {

      //console.log("draftCourse Create triggered");
      //console.log(userData);

   
      return new Promise((resolve, reject) => {
        axios
          .post('/lms/admin/course/draft', userData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    publishCourse(ctx, userData) {

      //console.log("publishCourse Create triggered");
      //console.log(userData);

   
      return new Promise((resolve, reject) => {
        axios
          .post('/survey/admin/surveypublish', userData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
